import { toggleExpanded, toggleHidden } from 'src/utils/dom-toggle';
import { Controller } from '@hotwired/stimulus';
import { emitter } from 'src/emitter';

export default class NavbarController extends Controller {
  public static targets = [
    'iconOpen',
    'iconClosed',
    'menu',
    'toggle',
    'notificationCount',
  ];

  private declare iconOpenTarget: HTMLElement;
  private declare iconClosedTarget: HTMLElement;
  private declare menuTarget: HTMLElement;
  private declare toggleTarget: HTMLElement;
  private declare notificationCountTarget: HTMLElement;

  public onNotificationEmit(data: any): void {
    if (data && data['count'] !== undefined) {
      this.notificationCountTarget.innerHTML = data['count'];
      if (data['count'] === 0) {
        this.notificationCountTarget.style.display = 'none';
      } else {
        this.notificationCountTarget.style.display = 'block';
      }
    }
  }

  public connect(): void {
    emitter.on('notification:read', this.onNotificationEmit.bind(this));
    emitter.on('notification:read_all', this.onNotificationEmit.bind(this));
  }
  public disconnect(): void {
    emitter.off('notification:read', this.onNotificationEmit);
    emitter.off('notification:read_all', this.onNotificationEmit);
  }

  public get opened(): boolean {
    return this.toggleTarget.getAttribute('aria-expanded') === 'true';
  }

  public set opened(next: boolean) {
    toggleExpanded(this.toggleTarget, next);

    toggleHidden(this.iconOpenTarget, !next);
    toggleHidden(this.iconClosedTarget, next);
    toggleHidden(this.menuTarget, !next);
  }

  public onToggle(e: MouseEvent): void {
    if (e.currentTarget !== this.toggleTarget) {
      return;
    }

    this.opened = !this.opened;
  }
}
