import { Context, Controller } from '@hotwired/stimulus';
import { v4 as uuid } from 'uuid';

export default class TemplateToDomController extends Controller {
  public static values = {
    id: String,
    target: String,
  };

  declare idValue: string;
  declare targetValue: string;

  constructor(context: Context) {
    super(context);
  }

  connect(): void {}

  disconnect(): void {}

  public onAdd(): void {
    const template = document.getElementById(this.idValue);
    if (!template || !(template instanceof HTMLTemplateElement)) {
      console.warn('[template] expected a template with id:', this.idValue);
      return;
    }

    const inflated = this.inflate(template);
    const [placement, query] = this.targetValue.split(':');

    let target: HTMLElement | null;

    switch (placement) {
      case 'after':
      case 'before': {
        target = this.element.closest(query) || document.querySelector(query);
        break;
      }

      case 'first': {
        target = document.querySelector(query);
        break;
      }
      case 'last': {
        const all = document.querySelectorAll(query);
        target = all.item(all.length - 1) as HTMLElement | null;
        break;
      }
      default: {
        console.warn(
          '[template] that placement modifier is not supported',
          placement,
        );
        return;
      }
    }

    if (!target) {
      console.warn('[template] expected a target for placement and query', {
        placement,
        query,
      });
      return;
    }

    switch (placement) {
      case 'after': {
        target.after(inflated);
        return;
      }

      case 'before': {
        target.before(inflated);
        return;
      }

      case 'first':
      case 'last': {
        target.append(inflated);
        return;
      }
    }
  }

  private inflate(template: HTMLTemplateElement) {
    const idsToGenerate = (
      template.getAttribute('data-template-ids') || ''
    ).split(' ');
    const attributesToChange = (
      template.getAttribute('data-template-id-attributes') || ''
    ).split(' ');

    const inflated = template.content.firstElementChild!.cloneNode(true);
    const ids: Record<string, string> = {};

    idsToGenerate.forEach((id) => {
      ids[id] = uuid();
    });

    const inspectable: (HTMLElement | Node)[] = [inflated];
    let current: HTMLElement | Node | undefined;

    // eslint-disable-next-line no-cond-assign
    while ((current = inspectable.shift())) {
      if (current instanceof HTMLElement) {
        const element = current;

        attributesToChange.forEach((attribute) => {
          if (element.hasAttribute(attribute)) {
            const currentValue = element.getAttribute(attribute) || '';
            const nextValue = idsToGenerate.reduce(
              (result, token) =>
                result.replace(new RegExp(token, 'g'), ids[token]),
              currentValue,
            );

            console.log(attribute, element, currentValue, nextValue);
            element.setAttribute(attribute, nextValue);
          }
        });
      }

      current.childNodes.forEach((node) => inspectable.push(node));
    }

    return inflated;
  }
}
