import { focusTrap } from '@primer/behaviors';
import { toggleExpanded, toggleHidden } from 'src/utils/dom-toggle';
import { Controller } from '@hotwired/stimulus';

export default class SidebarController extends Controller {
  public static targets = ['wrapper', 'overlay', 'menu', 'toggle', 'actions'];

  private declare wrapperTarget: HTMLElement;
  private declare overlayTarget: HTMLElement;
  private declare menuTarget: HTMLElement;
  private declare toggleTarget: HTMLElement;
  private declare actionsTarget: HTMLElement;

  private focusController: AbortController | undefined;

  public connect(): void {
    // Make sure it's closed
    toggleExpanded(this.toggleTarget, false);
    toggleHidden(this.wrapperTarget, true);

    // Make sure animations are set-up
    this.overlayTarget.classList.add('opacity-0');
    this.overlayTarget.classList.remove('opacity-100');
    this.menuTarget.classList.add('-translate-x-full');
    this.menuTarget.classList.remove('translate-x-0');
    this.actionsTarget.classList.add('opacity-0');
    this.actionsTarget.classList.remove('opacity-100');
  }

  public disconnect(): void {
    toggleExpanded(this.toggleTarget, false);
    toggleHidden(this.wrapperTarget, true);
  }

  public get opened(): boolean {
    return this.toggleTarget.getAttribute('aria-expanded') === 'true';
  }

  public set opened(next: boolean) {
    toggleExpanded(this.toggleTarget, next);
    document.body.classList.toggle('overflow-hidden', next);

    if (next) {
      toggleHidden(this.wrapperTarget, false);

      this.focusController = focusTrap(
        this.wrapperTarget,
        this.actionsTarget.querySelector('button') || undefined,
      );
    } else {
      setTimeout(() => {
        if (this.opened) {
          return;
        }
        toggleHidden(this.wrapperTarget, true);
      }, 300);

      // Focus the menu button
      this.focusController?.abort('Sidebar is closed');
      this.toggleTarget.focus();
    }

    requestAnimationFrame(() => {
      if (!this.toggleTarget) {
        return;
      }

      this.overlayTarget.classList.toggle('opacity-0', !next);
      this.overlayTarget.classList.toggle('opacity-100', next);

      this.menuTarget.classList.toggle('-translate-x-full', !next);
      this.menuTarget.classList.toggle('translate-x-0', next);

      this.actionsTarget.classList.toggle('opacity-0', !next);
      this.actionsTarget.classList.toggle('opacity-100', next);
    });
  }

  public onOpen(): void {
    this.opened = true;
  }

  public onClose(): void {
    this.opened = false;
  }
}
