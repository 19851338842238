import { FocusKeys, focusZone } from '@primer/behaviors';
import { toggleHidden } from 'src/utils/dom-toggle';
import { requestSubmit } from 'src/utils/form';
import { Context, Controller } from '@hotwired/stimulus';
import { onTransitionEnd } from 'src/utils/dom-transition';

/**
 * Enables the labels dialog for an individual todo
 */
export default class LabelsController extends Controller {
  public static targets = ['editButton', 'modal', 'labels'];
  public static values = {
    url: String,
  };

  private declare readonly modalTarget: HTMLElement;
  private declare readonly editButtonTarget: HTMLButtonElement;
  private declare readonly labelsTarget: HTMLElement;
  private declare readonly urlValue: string;

  private inflated: boolean = false;
  private focusController: AbortController | undefined;

  constructor(context: Context) {
    super(context);

    this.onKey = this.onKey.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);
  }

  public connect(): void {
    this.inflate();
  }

  public disconnect(): void {
    this.inflated = false;
  }

  get opened(): boolean {
    return this.editButtonTarget.getAttribute('aria-expanded') === 'true';
  }

  get disabled(): boolean {
    return this.editButtonTarget.disabled;
  }

  set opened(next: boolean) {
    this.editButtonTarget.setAttribute('aria-expanded', String(next));

    // this.inflate();

    if (this.inflated) {
      if (next) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  public onToggle(): void {
    if (this.disabled) {
      return;
    }

    this.opened = !this.opened;

    // Submit on close
    if (!this.opened) {
      const form = this.modalTarget.querySelector('form');
      requestSubmit(form);
    }
  }

  public onReplaced(
    event: Event & { detail: [unknown, unknown, XMLHttpRequest] },
  ): void {
    const [, , xhr] = event.detail;
    this.labelsTarget.innerHTML = xhr.response;
    this.opened = false;
  }

  private onKey(e: KeyboardEvent): void {
    if (!this.opened) {
      return;
    }

    switch (e.key) {
      case 'Escape': {
        e.preventDefault();
        this.onToggle();
        return;
      }

      case 'Tab': {
        e.preventDefault();
        this.onToggle();
        return;
      }
    }
  }

  private onOutsideClick(e: MouseEvent): void {
    if (e.target instanceof HTMLElement) {
      if (this.modalTarget == e.target || this.modalTarget.contains(e.target)) {
        return;
      }
    }

    this.onToggle();
  }

  private inflate(): void {
    if (this.inflated) {
      return;
    }

    const expected = 'text/vnd.kaboom.dialog.inflate';

    fetch(this.urlValue, {
      headers: { accept: `${expected}, application/problem+json; q=0.1` },
    })
      .then((response) => {
        if (!response.ok) {
          // TODO: read problem
          throw new Error('Failed to retrieve inflated dialog');
        }

        const contentType = response.headers.get('content-type') || '';

        if (contentType.startsWith(expected)) {
          return response.text();
        }

        if (contentType.includes(`variant=${expected}`)) {
          return response.text();
        }

        throw new Error(`Expected ${expected}, actual ${contentType}`);
      })
      .then((html) => {
        this.modalTarget.innerHTML = html;
        this.inflated = true;

        // If still opened, show it
        if (this.opened) {
          this.modalTarget.getBoundingClientRect();
          this.show();
        }
      });
  }

  private show(): void {
    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */

    // Remove animation (and force render)
    this.modalTarget.classList.remove('transition', 'ease-in', 'duration-75');
    this.modalTarget.getBoundingClientRect();

    // Remove "leaving" and "leaving.to"
    toggleHidden(this.modalTarget, false);
    this.modalTarget.classList.remove('transform', 'opacity-0', 'scale-95');

    // Position correctly
    // this.recalculatePosition();

    // Add "entering" and "entering.from" (and force render)
    this.modalTarget.classList.add('transform', 'opacity-0', 'scale-95');
    this.modalTarget.getBoundingClientRect();

    // Add back animation
    this.modalTarget.classList.add('transition', 'ease-out', 'duration-100');

    requestAnimationFrame(() => {
      if (!this.opened) {
        return;
      }

      // Remove "entering.from" and add "entering.to"
      this.modalTarget.classList.remove('opacity-0', 'scale-95');
      this.modalTarget.classList.add('opacity-100', 'scale-100');

      document.addEventListener('keydown', this.onKey);
      document.addEventListener('click', this.onOutsideClick);

      this.focusController = focusZone(this.modalTarget, {
        focusInStrategy: 'closest',
        focusOutBehavior: 'wrap',
        bindKeys: FocusKeys.ArrowVertical | FocusKeys.HomeAndEnd,
      });

      this.modalTarget.querySelector<HTMLElement>('[tabindex]')?.focus();
    });
  }

  private hide(): void {
    this.focusController?.abort('Labels modal is hidden');

    document.removeEventListener('keydown', this.onKey);
    document.removeEventListener('click', this.onOutsideClick);

    /**
     * Entering: "transition ease-out duration-100"
     *     From: "transform opacity-0 scale-95"
     *       To: "transform opacity-100 scale-100"
     *  Leaving: "transition ease-in duration-75"
     *     From: "transform opacity-100 scale-100"
     *       To: "transform opacity-0 scale-95"
     */

    // Enable animation (and force render)
    this.modalTarget.classList.remove('ease-out', 'duration-100');
    this.modalTarget.classList.add('transition', 'ease-in', 'duration-75');
    this.modalTarget.getBoundingClientRect();

    // Remove "entering" and "entering.to"
    // Add "leaving" and "leaving.from"
    this.modalTarget.classList.add('opacity-100', 'scale-100');

    requestAnimationFrame(() => {
      if (this.opened) {
        return;
      }

      // Remove "leaving.from" and add "leaving.to"
      this.modalTarget.classList.remove('opacity-100', 'scale-100');
      this.modalTarget.classList.add('opacity-0', 'scale-95');

      onTransitionEnd(this.modalTarget, 100, () => {
        if (this.opened) {
          return;
        }

        toggleHidden(this.modalTarget, true);
      });
    });
  }
}
