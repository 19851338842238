import { toggleHidden } from 'src/utils/dom-toggle';
import { isReducedMotion } from 'src/utils/media-query';
import { Context, Controller } from '@hotwired/stimulus';

export default class ReorderableController extends Controller {
  public static targets = ['dragHandle'];

  declare dragHandleTargets: Array<HTMLElement>;

  private lastDraggedElement: HTMLElement | undefined;
  private lastDragZone: HTMLElement | undefined;

  constructor(context: Context) {
    super(context);

    this.onHandleMouseDown = this.onHandleMouseDown.bind(this);
    this.onHandleTouchStart = this.onHandleTouchStart.bind(this);
    this.onHandleMouseUp = this.onHandleMouseUp.bind(this);
    this.onHandleTouchEnd = this.onHandleTouchEnd.bind(this);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.onDropZoneActivated = this.onDropZoneActivated.bind(this);
    this.onDropZonePolled = this.onDropZonePolled.bind(this);
    this.onDropZoneIgnored = this.onDropZoneIgnored.bind(this);
  }

  public connect(): void {
    this.dragHandleTargets.forEach((target) => {
      this.addDragHandle(target);
    });
  }

  public disconnect(): void {
    this.dragHandleTargets.forEach((target) => {
      this.removeDragHandle(target);
    });
  }

  public dragHandleTargetConnected(target: HTMLElement) {
    this.addDragHandle(target);
    this.reorder();
  }

  public dragHandleTargetDisconnected(target: HTMLElement) {
    this.removeDragHandle(target);
    this.reorder();
  }

  private onDragStart(event: DragEvent) {
    const target = event.currentTarget;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    event.stopPropagation();
    if (!target.draggable) {
      event.preventDefault();
      return;
    }

    this.lastDraggedElement = target;
    target.classList.add('bg-gray-50', 'dark:bg-gray-800', 'sepia');

    const group = target.getAttribute('data-reorderable-group')!;
    this.enableDropZones(group);

    // TODO: not generic
    if (target.classList.contains('--collapsible')) {
      target
        .querySelectorAll('[data-collapse-target="content"]')
        .forEach((element) => toggleHidden(element, true));

      this.collapseAll('[data-collapse-target="content"]');
    }
  }

  private onDragEnd(event: DragEvent) {
    console.info(
      '[dnd] end',
      this.lastDraggedElement,
      this.lastDragZone,
      event,
    );

    const target = event.currentTarget;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    event.stopPropagation();

    target.draggable = false;
    target.classList.remove('bg-gray-50', 'dark:bg-gray-800', 'sepia');

    const group = target.getAttribute('data-reorderable-group')!;
    this.disableDropZones(group);
    this.expandAll('[data-collapse-target="content"]');

    this.lastDraggedElement = undefined;

    if (this.lastDragZone) {
      this.lastDragZone.style.borderBottomWidth = '';
      this.lastDragZone.style.borderBottomStyle = '';
      this.lastDragZone.style.borderBottomColor = '';

      this.lastDragZone = undefined;
    }
  }

  private onDrop(event: DragEvent) {
    event.preventDefault();
    console.info(
      '[dnd] dropped',
      this.lastDraggedElement,
      this.lastDragZone,
      event,
    );

    if (this.lastDragZone !== event.currentTarget) {
      console.warn(
        '[dnd] expected last drag zone to match event target',
        this.lastDragZone,
        event.currentTarget,
      );
      return;
    }

    if (!this.lastDraggedElement) {
      console.warn('[dnd] did not expect last dragged element to be falsy');
      return;
    }

    this.lastDragZone.after(this.lastDraggedElement);

    this.reorder();

    const handle = this.lastDraggedElement.querySelector<HTMLButtonElement>(
      'button[data-reorderable-target="dragHandle"]',
    );
    if (handle) {
      handle.focus({ preventScroll: true });

      setTimeout(
        () =>
          requestAnimationFrame(() => {
            handle.scrollIntoView({
              behavior: isReducedMotion() ? 'auto' : 'smooth',
              block: 'start',
            });
          }),
        32,
      );
    }
  }

  public reorder() {
    // TODO: make generic
    this.element
      .querySelectorAll('[data-reorderable-group="section"]')
      .forEach((section, sectionIndex) => {
        section
          .querySelectorAll('[name^="measurements["][name$="[section]"]')
          .forEach((input) => {
            input.setAttribute('value', String(sectionIndex));
          });
      });

    // TODO: make generic
    this.element
      .querySelectorAll('[name^="measurements["][name$="[order]"]')
      .forEach((input, itemIndex) => {
        input.setAttribute('value', String(itemIndex));
      });
  }

  private onDropZoneActivated(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.currentTarget;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    if (this.lastDragZone) {
      if (target === this.lastDragZone) {
        return;
      }

      this.lastDragZone.style.borderBottomWidth = '';
      this.lastDragZone.style.borderBottomStyle = '';
      this.lastDragZone.style.borderBottomColor = '';
    }

    this.lastDragZone = target;

    if (this.lastDragZone == this.lastDraggedElement) {
      return;
    }

    target.style.borderBottomWidth = '4px';
    target.style.borderBottomStyle = 'double';
    target.style.borderBottomColor = 'rgba(16, 185, 129)';
  }

  private onDropZoneIgnored(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.currentTarget;
    if (!(target instanceof HTMLElement)) {
      return;
    }

    if (this.lastDragZone) {
      if (target === this.lastDragZone) {
        return;
      }

      this.lastDragZone.style.borderBottomWidth = '';
      this.lastDragZone.style.borderBottomStyle = '';
      this.lastDragZone.style.borderBottomColor = '';
    }

    this.lastDragZone = undefined;
  }

  private onDropZonePolled(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  private addDragHandle(target: HTMLElement) {
    const group = target.getAttribute('data-reorderable-group-handle');
    const element = target.closest(`[data-reorderable-group="${group}"]`);

    if (!element || !(element instanceof HTMLElement)) {
      return;
    }

    target.addEventListener('mousedown', this.onHandleMouseDown);
    // target.addEventListener('touchstart', this.onHandleTouchStart);
    target.addEventListener('mouseup', this.onHandleMouseUp);
    // target.addEventListener('touchend', this.onHandleTouchEnd);

    element.addEventListener('dragstart', this.onDragStart);
    element.addEventListener('dragend', this.onDragEnd);
  }

  private removeDragHandle(target: HTMLElement) {
    const group = target.getAttribute('data-reorderable-group-handle');
    const element = target.closest(`[data-reorderable-group="${group}"]`);

    target.removeEventListener('mousedown', this.onHandleMouseDown);
    // target.removeEventListener('touchstart', this.onHandleTouchStart);
    target.removeEventListener('mouseup', this.onHandleMouseUp);
    // target.removeEventListener('touchend', this.onHandleTouchEnd);

    if (!element || !(element instanceof HTMLElement)) {
      return;
    }

    element.removeEventListener('dragstart', this.onDragStart);
    element.removeEventListener('dragend', this.onDragEnd);
  }

  private onHandleMouseDown(event: MouseEvent) {
    const target = event.currentTarget;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    const group = target.getAttribute('data-reorderable-group-handle');
    const element = target.closest(`[data-reorderable-group="${group}"]`);

    if (!element || !(element instanceof HTMLElement)) {
      return;
    }

    element.draggable = true;
  }

  private onHandleTouchStart(event: TouchEvent) {
    const target = event.currentTarget;

    if (!(target instanceof HTMLElement) || event.touches.length !== 1) {
      event.preventDefault();
      return;
    }

    const group = target.getAttribute('data-reorderable-group-handle');
    const element = target.closest(`[data-reorderable-group="${group}"]`);

    if (!element || !(element instanceof HTMLElement)) {
      return;
    }

    element.draggable = true;
  }

  private onHandleMouseUp(event: MouseEvent) {
    const target = event.currentTarget;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    const group = target.getAttribute('data-reorderable-group-handle');
    const element = target.closest(`[data-reorderable-group="${group}"]`);

    if (!element || !(element instanceof HTMLElement)) {
      return;
    }

    element.draggable = false;
  }

  private onHandleTouchEnd(event: TouchEvent) {
    const target = event.currentTarget;

    if (!(target instanceof HTMLElement) || event.touches.length !== 0) {
      event.preventDefault();
      return;
    }

    const group = target.getAttribute('data-reorderable-group-handle');
    const element = target.closest(`[data-reorderable-group="${group}"]`);

    if (!element || !(element instanceof HTMLElement)) {
      return;
    }

    element.draggable = false;
  }

  private enableDropZones(zone: string) {
    this.element
      .querySelectorAll<HTMLElement>(`[data-reorderable-group]`)
      .forEach((dropZone) => {
        const dropZoneName = dropZone.getAttribute('data-reorderable-group');

        if (dropZoneName === zone) {
          dropZone.addEventListener('dragenter', this.onDropZoneActivated);
          dropZone.addEventListener('dragover', this.onDropZonePolled);
          dropZone.addEventListener('drop', this.onDrop);
        } else {
          dropZone.addEventListener('dragenter', this.onDropZoneIgnored);
        }
      });
  }

  private disableDropZones(zone: string) {
    this.element
      .querySelectorAll<HTMLElement>(`[data-reorderable-group]`)
      .forEach((dropZone) => {
        const dropZoneName = dropZone.getAttribute('data-reorderable-group');

        if (dropZoneName === zone) {
          dropZone.removeEventListener('dragenter', this.onDropZoneActivated);
          dropZone.removeEventListener('dragover', this.onDropZonePolled);
          dropZone.removeEventListener('drop', this.onDrop);
        } else {
          dropZone.removeEventListener('dragenter', this.onDropZoneIgnored);
        }
      });
  }

  private collapseAll(query: string) {
    requestAnimationFrame(() =>
      this.element
        .querySelectorAll(query)
        .forEach((element) => toggleHidden(element, true)),
    );
  }

  private expandAll(query: string) {
    requestAnimationFrame(() =>
      this.element
        .querySelectorAll(query)
        .forEach((element) => toggleHidden(element, false)),
    );
  }
}
