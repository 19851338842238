import { Context, Controller } from '@hotwired/stimulus';
import { Dialog, OneTimeDialog } from 'src/dialog';
import { v4 as uuid } from 'uuid';

export default class FormController extends Controller {
  public static values = {
    'source-url': String,
  };

  private declare hasSourceUrlValue: boolean;
  private declare sourceUrlValue: string;

  constructor(props: Context) {
    super(props);

    this.onFormError = this.onFormError.bind(this);
  }

  connect(): void {
    this.element.addEventListener('ajax:error', this.onFormError);
    this.element.addEventListener('turbo:submit-end', this.onFormError);
  }

  disconnect(): void {
    this.element.removeEventListener('ajax:error', this.onFormError);
    this.element.removeEventListener('turbo:submit-end', this.onFormError);
  }

  onFormError(event: Event) {
    if (!('detail' in event) || event.detail === null) {
      return;
    }

    // TODO: Do not support submit-end yet
    if (!Array.isArray(event.detail)) {
      return;
    }

    const message = event.detail[0] || 'no additional details provided';
    const title = event.detail[1];
    const status = event.detail[2].status;

    if (status >= 200 && status < 400) {
      return;
    }

    console.error(`${title} (${status})`);
    console.error(message);

    const template = document.getElementById('form-modal-template');

    if (!('content' in document.createElement('template')) || !template) {
      alert(`${title} (${status}): please report this to your Opus consultant`);
      return;
    }

    const id = uuid();

    const description = this.descriptionFor(status, message);
    const copy = template.cloneNode(true) as HTMLTemplateElement;

    /*
    let current: Node | undefined = copy;
    const children: Node[] = [];

    while (current) {
      current.childNodes.forEach((node) => {
        children.push(node);
      });

      if (current instanceof HTMLElement) {
        for (let i = 0; i < current.attributes.length; i++) {
          const attribute = current.attributes.item(i);
          if (attribute) {
            if (attribute.value) {
              attribute.value = attribute.value.replace(/:id/g, id);
            }
          }
        }
      }

      if (current.textContent) {
        current.textContent = current.textContent.replace(/:title/g, title);
      }

      current = children.shift();
    }
    */

    copy.innerHTML = copy.innerHTML
      .replace(/:id/g, id)
      .replace(/:title/g, this.titleFor(status, title))
      .replace(/:description/g, description);

    const inflated = copy.content.firstElementChild!.cloneNode(true);
    template.parentElement!.append(inflated);

    const focus = document.activeElement || this.element;
    new OneTimeDialog(id, true).open(focus);
  }

  private descriptionFor(status: number, message: string): string {
    const here = this.hasSourceUrlValue
      ? `<a
        href="${this.sourceUrlValue}"
        target="__blank"
        class="underline hover:no-underline focus:no-underline text-theme-primary-dark-500 hover:text-theme-primary-dark-700 transition">
          confirm here (opens in a new tab)
        </a>`
      : 'confirm';

    if (status >= 400 && status < 500) {
      if (status === 404) {
        return `There was a problem with your submission. <br />
          We could not find or retrieve a resource. <br />
          <br />
          If you are certain that the fields are filled in correctly, please notify your Opus consultant so we can resolve this for you. <br />
          <br />
          <strong>Details</strong><br />
          <code class="font-mono text-xs">${message.substring(0, 255)}...</code>
        `;
      }

      if (status === 409) {
        return `Someone else made a change that is conflicting with your submission. <br />
        <br />
        Please go back, inspect the changes, and resubmit your request.`;
      }

      if (status === 413) {
        return `The submission is too large. You can submit up to 1000 MB (unless otherwise specified) by first opening the modal with JavaScript enabled instead of directly drag-and-dropping a file or attempting this without JavaScript enabled.<br />
        <br />
        In all other cases, please shrink the file to at most 15 MB and try again.`;
      }

      return `There was a problem with your submission. <br />
        <br />
        Please check if the fields are filled in correctly and try again. <br />
        <br />
        <strong>Details</strong><br />
        <code class="font-mono text-xs">${message.substring(0, 255)}...</code>`;
    }

    if (status >= 500 && status < 600) {
      if (status === 500) {
        return `Something broke on our end and we <strong>have been notified</strong> about this issue. <br />
          <br />
          You cannot resolve this from your end. Notify your Opus consultant if your submitted request is critical.`;
      }

      if (status === 502) {
        return `The server was temporarily offline. However, your request <em>may have been processed</em>. <br />
          <br />
          Please ${here} in a few minutes that your request has been processed. You may then try again.`;
      }

      if (status === 504) {
        return `We could not process your request in time, but it was accepted. <br />
          <br />
          Please <strong>do not retry</strong> right away, but in a while (up to 30 minutes) ${here} that your submission has been applied. <br />
          <br />
          You may continue your work without delay. Notify your Opus consultant if this occurs regularly.`;
      }

      return `Something broke on our end (${status}). <br />
        <br />
        You cannot resolve this from your end. Notify your Opus consultant if your submitted request is critical. <br />
        <br />
        <strong>Details</strong><br />
        <code class="font-mono text-xs">${message.substring(0, 255)}...</code>`;
    }

    if (status === 0) {
      return `The connection was terminated. <br />
      <br />
      Please check that you have an active internet connection and that your firewall does not block requests to Opus Safety Cloud.
      <br />
      In particular the following domain must be whitelisted as well:
      <code class="font-mono text-xs">kaboom-files.storage.opus-safety.delftsolutions.nl</code>.
      <br />
      If the problem persists and you are confident that you have an active connection and correctly configured firewall, please Notify your Opus consultant.
      `;
    }

    return `Something broke on our end (${status}). <br />
      <br />
      You cannot resolve this from your end. Notify your Opus consultant if your submitted request is critical. <br />
      <br />
      <strong>Details</strong><br />
      <code class="font-mono text-xs">${message.substring(0, 255)}...</code>`;
  }

  private titleFor(status: number, title: string): string {
    if (status >= 400 && status < 500) {
      if (status === 404) {
        return 'Resource not found';
      }

      if (status === 409) {
        return 'Conflicting change';
      }

      if (status === 413) {
        return 'Payload too large';
      }

      return 'Submission issue';
    }

    if (status >= 500 && status < 600) {
      if (status === 500) {
        return 'Server issue';
      }

      if (status === 502) {
        return 'Server is offline';
      }

      if (status === 504) {
        return 'Server is slow';
      }

      return 'Unknown server issue';
    }

    if (status === 0) {
      return 'Connection issue';
    }

    return `Unknown issue ${status} (${title})`;
  }
}
